import React, { Component, useState,useEffect,useRef} from 'react'
import { Link } from 'react-router-dom'
import { TabContent, TabPane, Nav, NavItem, NavLink, Col, Card, CardBody, CardText, CardTitle } from 'reactstrap'
import MetaTags from 'react-meta-tags'
import classnames from 'classnames'
import Slider from 'react-slick'
import Header from './header'
import Footer from './NewFooter'
import WOW from '../../animation/Wow'
//import ReactWOW from 'react-wow'
import request from '../../api/api'
import Cookie_svg from '../../images/home/cookie.svg'
import { toast, ToastContainer } from 'react-toastify'
//import Anime from 'react-anime';
import AuthetiCationModal from './AuthetiCationModal'
import { openPopupWidget } from 'react-calendly'

// Images
import shiftManagement from '../../images/homes-new/features-shiftManagement.png'
import timesheets from '../../images/homes-new/features-timesheets.png'
import management from '../../images/homes-new/features-management.png'
import invoices from '../../images/homes-new/features-invoices.png'
import compliance from '../../images/homes-new/features-compliance.png'
import recruitment from '../../images/homes-new/features-recruitment.png'

// Home New Images
import banner_video from '../../images/home_new_img/video.png'
import manage_your_business from '../../images/home_new_img/manage_your_business.webp'
import pmb_icon1 from '../../images/home_new_img/ssm.svg'
import pmb_icon2 from '../../images/home_new_img/rtu.svg'
import pmb_icon3 from '../../images/home_new_img/stam.svg'
import pmb_icon4 from '../../images/home_new_img/ati.svg'
import growth1 from '../../images/home_new_img/agency.svg'
import growth2 from '../../images/home_new_img/shift_booked.svg'
import growth3 from '../../images/home_new_img/user.svg'
import growth4 from '../../images/home_new_img/hours_worked.svg'
import double_qot_top from '../../images/home_new_img/double_qot_top.png'
import double_qot_bot from '../../images/home_new_img/double_qot_bot.png'

import shift_schedule from '../../images/home_new_img/shiftscheduling.webp'
import centralised_timesheets from '../../images/home_new_img/centralizedtimesheets.webp'
import online_time_attendance from '../../images/home_new_img/onlinetimeandattendance.webp'


import automatedtimeattendancetracker from '../../images/home_new_img/automatedtimeattendancetracker.webp'
import easilycompareattendanceawithworkschedules from '../../images/home_new_img/easilycompareattendanceawithworkschedules.webp'
import discover_getshift from '../../images/home_new_img/discover_getshift.webp'
import notification from '../../images/home_new_img/notification.svg'
import clock from '../../images/home_new_img/clock.svg'
import time from '../../images/home_new_img/time.svg'
import i_phone from '../../images/home_new_img/i_phone.webp'
import play_store from '../../images/home_new_img/play_store.svg'
import app_store from '../../images/home_new_img/app_store.svg'
import wt_rt_arw from '../../images/home_new_img/wt_rt_arw.svg'


import home_banner from '../../images/home_new_img/home_banner.webp'
import tabscrolling from '../../images/home_new_img/tabscrolling.webp'
import exclusive_app_bg from '../../images/home_new_img/exclusive_app_bg.webp'
import embeds_bg from '../../images/home_new_img/embeds.webp'

import { BookDemoButton } from './Features'

let pageSettings = {
      backgroundColor: 'ffffff',
      hideEventTypeDetails: false,
      hideLandingPageDetails: false,
      primaryColor: '00a2ff',
      textColor: '4d5055'
   },
   // url = 'https://calendly.com/agencyportal',
   url = 'https://calendly.com/d/cks-3hf-fbt/demo?month=2022-02',
   prefill = {},
   utm = {}

export default class NewHomePage extends Component {
   constructor(props) {
      super(props)
      this.state = {
         activeTab: '1',
         activeTabs: '1',
         email: '',
         showLoginModal: false,
         autheticateTab: 1,
         cookie_acceptance: false,
         cookie_terms: true
      }
      this.sliderRef = null
   }
   componentDidMount() {
      window.scrollTo(0, 0)
      new WOW().init()
      const cookie_accepted = JSON.parse(localStorage.getItem('Cookie_acceptance'))
      if (cookie_accepted) {
         this.setState({ cookie_acceptance: cookie_accepted })
      } else {
         this.setState({ cookie_acceptance: false })
      }

      const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false

      if (subdomain !== 'www') {
         return this.props.history.push('/agency-login')
      }

      const arrbtn = document.getElementById('commonArrow')
      window.onscroll = function() {
         scrollFunction()
      }
      function scrollFunction() {
         if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            arrbtn.style.display = 'block'
         } else {
            arrbtn.style.display = 'none'
         }
      }
   }
   toggle = activeTab => {
      this.setState({ activeTab: activeTab })
      if (this.sliderRef) {
         this.sliderRef.slickGoTo(+activeTab)
      }
   }
   toggles = activeTabs => {
      this.setState({ activeTabs: activeTabs })
   }
   toggles = activeTabs => {
      const activeSlideIndex = parseInt(activeTabs, 10) - 1
      this.setState({ activeTabs, activeSlideIndex })
      if (this.sliderRef) {
         this.sliderRef.slickGoTo(+activeSlideIndex)
      }
   }
   menufun = () => {
      document.getElementById('varmenu').classList.add('shower-menus')
      document.getElementById('shbody').classList.add('shower-body')
   }
   hidingmenu = () => {
      document.getElementById('varmenu').classList.remove('shower-menus')
      document.getElementById('shbody').classList.remove('shower-body')
   }
   clomenus = () => {
      document.getElementById('varmenu').classList.remove('shower-menus')
      document.getElementById('shbody').classList.remove('shower-body')
   }
   upperFun = () => {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
   }
   onChange = e => {
      this.setState({
         email: e.target.value
      })
   }
   ValidateEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
         return true
      }
      return false
   }
   onTryFree = e => {
      const { email } = this.state
      if (email === '') {
         toast.error('Please enter Email')
      } else if (this.ValidateEmail(email) === false) {
         toast.error('You have entered an invalid email address!')
      } else {
         request({
            url: '/site/submit/try_for_free',
            method: 'POST',
            data: {
               email: this.state.email
            }
         }).then(res => {
            /* this.props.history.push({
						   pathname: "/agency-register",
						   state: {
							   email: this.state.email
						   }
					   }) */
            this.setState({ autheticateTab: '2' })
            localStorage.setItem('tryforfree_email', this.state.email)
            this.setState({ showLoginModal: true })
         })
      }
   }
   openSigninModal = (e, tab) => {
      this.setState({ showLoginModal: true })
      this.setState({ autheticateTab: tab })
   }
   closeSigninModal = e => {
      this.setState({ showLoginModal: false })
   }
   openInNewTab = url => {
      window.open(url, '_blank', 'noreferrer')
   }
   onClick = () => openPopupWidget({ url, prefill, pageSettings, utm })
   cookieAcceptance = () => {
      this.setState({ cookie_acceptance: true })
      localStorage.setItem('Cookie_acceptance', true)
   }
   render() {
      const settings = {
         dots: false,
         infinite: true,
         speed: 1500,
         slidesToShow: 6,
         slidesToScroll: 1,
         autoplay: true,
         // speed: 1000,
         smartSpeed: 1000,
         autoplaySpeed: 1000,
         responsive: [
            {
               breakpoint: 1200,
               settings: {
                  slidesToShow: 5,
                  slidesToScroll: 1
               }
            },
            {
               breakpoint: 1024,
               settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1
               }
            },
            {
               breakpoint: 767,
               settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  infinite: true
               }
            },
            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1
               }
            },
            {
               breakpoint: 480,
               settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
               }
            }
         ]
      }

      const shiftslider = {
         dots: false,
         infinite: true,
         speed: 1500,
         slidesToShow: 1,
         slidesToScroll: 1,
         // autoplay: true,
         // speed: 1000,
         accesibility: false,
         draggable: false,
         swipe: false,
         touchMove: false,
         smartSpeed: 1000,
         autoplaySpeed: 1000,
         responsive: [
            {
               breakpoint: 1200,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            },
            {
               breakpoint: 1024,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            },
            {
               breakpoint: 767,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true
               }
            },
            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            },
            {
               breakpoint: 480,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            }
         ]
      }

      const multisliders = {
         dots: true,
         infinite: true,
         speed: 1000,
         centerMode: true,
         slidesToShow: 2,
         touchMove: true,
         accesibility: false,
         draggable: false,
         margin: 10,
         centerPadding: '10px',
         slidesToScroll: 1,
         autoplay: true,
         arrows: false,
         smartSpeed: 3000,
         autoplaySpeed: 3000,

         responsive: [
            {
               breakpoint: 1024,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            },
            {
               breakpoint: 767,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            },
            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            },
            {
               breakpoint: 480,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            }
         ]
      }
      return (
         <>
            <MetaTags>
               <title>Shift and Employee Management Software - Getshifts</title>
               <meta
                  name="keyword"
                  content="best shift management software, shift management software for employee,online shift management software, shift Scheduling software"
               />
               <meta
                  name="description"
                  content="Discover the ultimate suite of tools for Strong business operations with compliance, recruitment, invoicing, employee, timesheet, and shift management software. Streamline your processes efficiently and effectively with our all-inclusive solutions"
               />
            </MetaTags>
               <Header id="hedspace"></Header>
            {/* Home Section -- Start */}
            <section className="home_banner pt-4">
               <div className="container">
                  <h5>
                     Streamline Your <br /> Shift and Staff <span>Management</span>
                  </h5>
                  <p>
                     Your Complete Agency Management Software to Automate Your Business with <br /> Everything You Need in One Powerful Platform.
                  </p>
                  <div className="get_start_book_demo mt-5">
                     <Link className="purple_bg" to="/signup">
                        Get started <span>&nbsp;- Its Free</span>
                     </Link>
                     <BookDemoButton />
                  </div>
                  <p className="nocredit">No credit card required</p>
                  <div className="video_run">
                     {/* <img src={banner_video} alt="Video" /> */}

                     {/* <div style={{ position: 'relative', overflow: 'hidden', aspectRatio: '1920/1080' }}>
                        <iframe
                           // src="https://share.synthesia.io/embeds/videos/0bdc7b20-2654-44d9-b4ad-8ae233bd7c33"
                           src="https://share.synthesia.io/embeds/videos/0bdc7b20-2654-44d9-b4ad-8ae233bd7c33"
                           loading="lazy"
                           title="Synthesia video player - Get Shifts: Temporary Staffing Solution"
                           allowFullScreen
                           allow="encrypted-media; fullscreen;"
                           style={{
                              position: 'absolute',
                              width: '100%',
                              height: '100%',
                              top: 0,
                              left: 0,
                              border: 'none',
                              padding: 0,
                              margin: 0,
                              overflow: 'hidden'
                           }}
                        />
                     </div> */}
                     <VideoEmbed />


                  </div>
               </div>
               {/* <video width={'100%'} autoPlay loop src={home_banner} ></video> */}
               {/* <video autoPlay loop muted className="bg_play_video">
                  <source src={home_banner} type="video/mp4" />
               </video> */}
               <img src={home_banner} alt="home_banner" className="bg_play_video" loading='lazy'  />
            </section>

            {/* Home Section -- End */}

            {/* Client Logo Section - Start */}
            <section className="client_logos mt-5">
               <div className="container">
                  <div className="clients_child row aic">
                     <Col md="3">
                        <p>
                           Trusted by over 1000 companies <br /> of all sizes
                        </p>
                     </Col>
                     <Col md="9">
                        <div className="our-clients">
                           <Slider {...settings}>
                              <div className="client-lists">
                                 <img src={`${process.env.PUBLIC_URL}/img/home/angel.png`} alt="img" loading='lazy' />
                              </div>
                              <div className="client-lists">
                                 <img src={`${process.env.PUBLIC_URL}/img/home/delight.png`} alt="img" loading='lazy' />
                              </div>
                              <div className="client-lists">
                                 <img src={`${process.env.PUBLIC_URL}/img/home/JHN.png`} alt="img" loading='lazy' />
                              </div>
                              <div className="client-lists">
                                 <img src={`${process.env.PUBLIC_URL}/img/home/mighty.png`} alt="img" loading='lazy' />
                              </div>
                              <div className="client-lists">
                                 <img src={`${process.env.PUBLIC_URL}/img/home/standards.png`} alt="img" loading='lazy' />
                              </div>
                              <div className="client-lists">
                                 <img src={`${process.env.PUBLIC_URL}/img/home/relief.png`} alt="img" loading='lazy' />
                              </div>
                              <div className="client-lists">
                                 <img src={`${process.env.PUBLIC_URL}/img/home/angel.png`} alt="img" loading='lazy'/>
                              </div>
                              <div className="client-lists">
                                 <img src={`${process.env.PUBLIC_URL}/img/home/delight.png`} alt="img" loading='lazy' />
                              </div>
                              <div className="client-lists">
                                 <img src={`${process.env.PUBLIC_URL}/img/home/JHN.png`} alt="img" loading='lazy' />
                              </div>
                              <div className="client-lists">
                                 <img src={`${process.env.PUBLIC_URL}/img/home/mighty.png`} alt="img" loading='lazy' />
                              </div>
                              <div className="client-lists">
                                 <img src={`${process.env.PUBLIC_URL}/img/home/standards.png`} alt="img" loading='lazy' />
                              </div>
                              <div className="client-lists">
                                 <img src={`${process.env.PUBLIC_URL}/img/home/relief.png`} alt="img" loading='lazy' />
                              </div>
                           </Slider>
                        </div>
                     </Col>
                  </div>
               </div>
            </section>

            {/* Client Logo Section - End */}

            {/* Powerful Manage Business Section - Start */}
            <section className="powerful_manage_business mt-5">
               <div className="container row aic">
                  <Col md="6" sm="12" className="left_side">
                     <img src={manage_your_business} alt="Manage Your Business" loading='lazy' />
                  </Col>
                  <Col md="6" sm="12" className="right_side">
                     <h6>A Smarter Way to Manage Your Workforce</h6>
                     <h6>Run your business effortlessly with Get Shifts</h6>
                     <p className="powersec mt-4">
                        <img src={pmb_icon1} alt="simply staff managenent" loading='lazy' />
                        <span><b>Simple staff management:</b> Easy tools to schedule, swap, and manage shifts.</span>
                     </p>
                     <p className="powersec">
                        <img src={pmb_icon2} alt="real time-updates" loading='lazy' />
                        <span><b>Real-time updates:</b> Stay in the know with instant notifications and insights.</span>
                     </p>
                     <p className="powersec">
                        <img src={pmb_icon3} alt="save time and money" loading='lazy' />
                        <span><b>Save time and money:</b> Automate manual tasks like scheduling, payroll, and compliance checks, reducing admin hours and cutting costly errors.</span>
                     </p>
                     <p className="powersec">
                        <img src={pmb_icon4} alt="automated time sheet and invoices" loading='lazy' />
                        <span><b>Streamlined timesheets and invoicing:</b> Generate accurate records and invoices in just a few clicks, ensuring no missed payments or overcharges.</span>
                     </p>
                     <div className="container">
                        <h1 style={{fontSize:"1.4rem"}}>Get Shifts – the better way to manage your team.</h1>
                        <div className="get_start_book_demo mt-5">
                           <Link className="purple_bg" to="/signup">
                              Get started <span>&nbsp;- Its Free</span>
                           </Link>
                           <BookDemoButton />
                        </div>
                     </div>
                  </Col>
               </div>
            </section>
            {/*Powerful Manage Business Section - End */}

            {/* Tabing Slider Section - Start */}
            <section className="tabbing_scroll mt-5 pt-5">

               <img src={tabscrolling} alt="tabscrolling" className="bg_play_video" loading='lazy'  />

               {/* <video autoPlay loop muted className="bg_play_video">
                  <source src={tabscrolling} type="video/mp4" />
               </video> */}

               {/* <video width={'100%'} autoPlay loop src={tabscrolling} ></video> */}
               <div className="container">
                  <Nav>
                     <NavItem>
                        <NavLink
                           className={classnames({ active: this.state.activeTabs === '1' })}
                           onClick={() => {
                              this.toggles('1')
                           }}
                        >
                           Shift
                        </NavLink>
                     </NavItem>
                     <NavItem>
                        <NavLink
                           className={classnames({ active: this.state.activeTabs === '2' })}
                           onClick={() => {
                              this.toggles('2')
                           }}
                        >
                           Timesheets
                        </NavLink>
                     </NavItem>
                     <NavItem>
                        <NavLink
                           className={classnames({ active: this.state.activeTabs === '3' })}
                           onClick={() => {
                              this.toggles('3')
                           }}
                        >
                           Employee
                        </NavLink>
                     </NavItem>
                     <NavItem>
                        <NavLink
                           className={classnames({ active: this.state.activeTabs === '4' })}
                           onClick={() => {
                              this.toggles('4')
                           }}
                        >
                           Invoicing
                        </NavLink>
                     </NavItem>
                     <NavItem>
                        <NavLink
                           className={classnames({ active: this.state.activeTabs === '5' })}
                           onClick={() => {
                              this.toggles('5')
                           }}
                        >
                           Compliance
                        </NavLink>
                     </NavItem>
                     <NavItem>
                        <NavLink
                           className={classnames({ active: this.state.activeTabs === '6' })}
                           onClick={() => {
                              this.toggles('6')
                           }}
                        >
                           Recruitment
                        </NavLink>
                     </NavItem>
                  </Nav>
                  <div className="tab_head">
                     <TabContent activeTab={'1'} className="sldd-parts">
                        <TabPane tabId="1" className="show-gals">
                           <Slider /* onSwipe={(...e) => console.log('swipe', e)} */ {...shiftslider} ref={slide => (this.sliderRef = slide)}>
                              <div className="shift-arrag">
                                 <p>
                                    Streamline staff scheduling with Agency Portal Shift Management module; the central, most powerful and intuitive module of
                                    the Agency Portal platform that lets you create, edit and view all your staff shifts and schedules online.
                                 </p>
                                 <img src={shiftManagement} alt="shiftManagement" loading="lazy" />
                              </div>
                              <div className="shift-arrag">
                                 <p>
                                    Streamline staff scheduling with Agency Portal Shift Management module; the central, most powerful and intuitive module of
                                    the Agency Portal platform that lets you create, edit and view all your staff shifts and schedules online.
                                 </p>
                                 <img src={timesheets} alt="timesheets" loading="lazy" />
                              </div>
                              <div className="shift-arrag">
                                 <p>
                                    Streamline staff scheduling with Agency Portal Shift Management module; the central, most powerful and intuitive module of
                                    the Agency Portal platform that lets you create, edit and view all your staff shifts and schedules online.
                                 </p>
                                 <img src={management} alt="management" loading="lazy" />
                              </div>
                              <div className="shift-arrag">
                                 <p>
                                    Streamline staff scheduling with Agency Portal Shift Management module; the central, most powerful and intuitive module of
                                    the Agency Portal platform that lets you create, edit and view all your staff shifts and schedules online.
                                 </p>
                                 <img src={invoices} alt="invoices" loading="lazy" />
                              </div>
                              <div className="shift-arrag">
                                 <p>
                                    Streamline staff scheduling with Agency Portal Shift Management module; the central, most powerful and intuitive module of
                                    the Agency Portal platform that lets you create, edit and view all your staff shifts and schedules online.
                                 </p>
                                 <img src={compliance} alt="compliance" loading="lazy" />
                              </div>
                              <div className="shift-arrag">
                                 <p>
                                    Streamline staff scheduling with Agency Portal Shift Management module; the central, most powerful and intuitive module of
                                    the Agency Portal platform that lets you create, edit and view all your staff shifts and schedules online.
                                 </p>
                                 <img src={recruitment} alt="recruitment" loading="lazy" />
                              </div>
                           </Slider>
                        </TabPane>
                     </TabContent>
                     <div className="get_start_book_demo df jcc pb-4">
                        <Link className="purple_bg" to="/signup">
                           Get started <span>&nbsp;- Its Free</span>
                        </Link>
                        <BookDemoButton />
                     </div>
                  </div>
               </div>
            </section>
            {/* Tabing Slider Section - End */}

            {/* Supercharge Your Growth Section - Start */}
            <section className="supercharges_growth mt-5">
               <div className="container">
                  <h4> Supercharge your growth</h4>
                  <p>
                     Get Shifts frees up a bulk of your time and makes running your business a breeze <br /> by automating your business and admin processes.{' '}
                  </p>
               </div>
               <div className="lists_clients mt-4">
                  <div className="clients_cates">
                     <div>
                        <img src={growth1} alt="Agencies" loading='lazy' />
                        <h3> 1000+ </h3>
                        <p> Agencies </p>
                     </div>
                  </div>
                  <div className="clients_cates">
                     <div>
                        <img src={growth2} alt="User" loading='lazy' />
                        <h3> 50,000+ </h3>
                        <p> Shifts Booked </p>
                     </div>
                  </div>
                  <div className="clients_cates">
                     <div>
                        <img src={growth3} alt="Shifts Booked" loading='lazy' />
                        <h3> 500,000+ </h3>
                        <p>Users</p>
                     </div>
                  </div>
                  <div className="clients_cates">
                     <div>
                        <img src={growth4} alt="Hours Worked" loading='lazy' />
                        <h3> 1M+ </h3>
                        <p> Hours Worked </p>
                     </div>
                  </div>
               </div>
            </section>
            {/* Supercharge Your Growth Section - End */}

            {/* Don't Only Listen To Us Section - Start */}
            <div className="only_listen mt-5 pt-5">
               <div className="container">
                  <div className="row aic">
                     <div className="col-lg-7 col-md-12 col-sm-12">
                        <h6>Don't only listen to us</h6>
                        <h5>
                           Adopting <span>Get Shifts</span> is like having <br /> 3 extra hours in an day*
                        </h5>
                        <div className="get_start_book_demo mt-4">
                           <Link className="purple_bg" to="/signup">
                              Get started <span>&nbsp;- Its Free</span>
                           </Link>
                           <BookDemoButton />
                        </div>
                     </div>
                     <div className="col-lg-5 col-md-12 col-sm-12">
                        <TestimonialTabs></TestimonialTabs>
                     </div>
                  </div>
               </div>
            </div>
            {/* Don't Only Listen To Us Section - End */}

            {/* Shift Scheduling- Centralized Timesheets- Centralized Timesheets Section - Start */}
            <div className="ssctotaa mt-5">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-6 ">
                        <div className="shiftscheduling">
                           <img src={shift_schedule} alt="Shift Scheduling" loading='lazy' />
                           <h4>Shift Scheduling</h4>
                           <p>
                              One click is all you need to notify employees of new schedule updates by email, text message, or push notification. Your staff can
                              use Get Shifts iOS and Android mobile apps to access their shift schedules and messages, to manage their availability, and any
                              other information.
                           </p>
                        </div>
                     </div>
                     <div className="col-lg-6 ">
                        <div className="onlinetimeandattendance">
                           <img src={centralised_timesheets} alt="Centralised Timesheets for All Your Employees" />
                           <h4>Centralised Timesheets</h4>
                           <p>
                              Accelerate your timesheet creation process and prevent unnecessary retranscriptions by making time entries easily accessible
                              online to your managers. As needed, you can allow employees to access their own timesheets and to add or modify entries from their
                              very own mobile phone app, tablet, or computer.
                           </p>
                        </div>
                     </div>
                     <div className="col-lg-12">
                        <div className="centralisedtimesheets row aic">
                           <div className="col-lg-6">
                              <img src={online_time_attendance} alt="Online Time and Attendance" loading='lazy' />
                           </div>
                           <div className="col-lg-6">
                              <h4>Online Time and Attendance</h4>
                              <p>
                                 Depending on your needs, employees can clock in and clock out their time using a mobile time clock app on iPhone or Android, a
                                 phone time clock, a computer time clock, or a kiosk on a tablet. Time and Attendance automatically adjusts your employees’
                                 timesheets in accordance with your established presets. Then, all you need to do is to approve your timesheets online, create
                                 invoices and export them to payroll.
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* Shift Scheduling- Centralized Timesheets- Centralized Timesheets Section - End */}

            {/* Automated Time -- Easily Compare Section - Start */}
            <div className="automatedtime_easilycompare mt-5">
               <div className="container">
                  <div className="row aic">
                     <div className="col-lg-6 col-md-6 col-sm-12">
                        <h5>
                           Automated Time And Attendance <br /> Tracker
                        </h5>
                        <p>
                           Employee attendance is automatically tracked in Agency Portal when they clock in clock out. As needed, you can set up certain rules
                           to automatically adjust clocked hours.
                        </p>
                     </div>
                     <div className="col-lg-6 col-md-6 col-sm-12">
                        <img src={automatedtimeattendancetracker} alt="automatedtimeattendancetracker"  loading='lazy'/>
                     </div>
                  </div>
                  <div className="row fdcr aic">
                     <div className="col-lg-6 col-md-6 col-sm-12">
                        <img src={easilycompareattendanceawithworkschedules} alt="easilycompareattendanceawithworkschedules" loading='lazy' />
                     </div>
                     <div className="col-lg-6 col-md-6 col-sm-12">
                        <h5>
                           Easily Compare Attendance <br /> With Work Schedules
                        </h5>
                        <p>
                           Quickly spot discrepancies between scheduled hours and clock in and clock out hours. Make better decisions regarding the number of
                           hours to pay; avoid paying for hours that weren’t actually worked for.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
            {/* Automated Time -- Easily Compare Section - End */}

            {/* Discover Get Shift Section - Start */}
            <div className="discover_getshift">
               <div className="container">
                  <div className="row aic py-5">
                     <div className="col-lg-6 left_side">
                        <p>
                           Discover how Get Shifts can revolutionise your workforce management. Schedule a demo with our team to see how our platform can
                           streamline your scheduling and improve efficiency.
                        </p>
                        <div className="get_start_book_demo jcs">
                           <Link className="purple_bg" to="/signup">
                              Get started <span>&nbsp;- Its Free</span>
                           </Link>
                           <BookDemoButton />
                        </div>
                     </div>
                     <div className="col-lg-6 right_side">
                        <img src={discover_getshift} alt="discover_getshifts" loading='lazy' />
                     </div>
                  </div>
               </div>
            </div>
            {/* Discover Get Shift Section - End */}

            {/* Notification Clock Time - Section - Start */}
            <div className="notification_clock_time mt-5 py-5">
               <div className="container">
                  <div className="row aic">
                     <div className="col-lg-4">
                        <div className="notification">
                           <img src={notification} alt="notification" loading='lazy' />
                           <h5>
                              Reduce No-Shows <br /> & Lateness
                           </h5>
                           <p>
                              Late employees are automatically alerted via an attendance notification. You can set the system to send you an alert when
                              employees don’t clock in or out, and customise exactly when you and your employees should be notified.
                           </p>
                        </div>
                     </div>
                     <div className="col-lg-4">
                        <div className="clock">
                           <img src={clock} alt="clock" loading='lazy' />
                           <h5>
                              GPS Time <br /> Clock
                           </h5>
                           <p>
                              Define a place and perimeter within which your employees must clock in to record their punch in and punch out entries. You can
                              then be sure that they are actually recording their attendance from where they are supposed to work.
                           </p>
                        </div>
                     </div>
                     <div className="col-lg-4">
                        <div className="time">
                           <img src={time} alt="time" loading='lazy' />
                           <h5>
                              Save The <br /> Time
                           </h5>
                           <p>
                              Get Shifts is an employee scheduling software designed specifically to process information optimally and accelerate
                              decision-making processes. The app helps you better plan your resources and employees.
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* Notification Clock Time - Section - End */}

            {/* Exclusive App Section - Start  */}
            <div className="exclusive_app">
               {/* <video width={'100%'} autoPlay loop src={exclusive_app_bg} ></video> */}
               {/* <video autoPlay loop muted className="bg_play_video bg_video">
                  <source src={exclusive_app_bg} type="video/mp4" />
               </video> */}
               <img src={exclusive_app_bg} alt="exclusive_app_bg" className="bg_play_video" loading='lazy'  />
               <div className="app_play">
                  <a href="https://apps.apple.com/us/app/agency-portal/id1484083870" rel="noopener noreferrer" target="_blank">
                     <img src={app_store} alt="app Store" loading='lazy' />
                  </a>

                  <a href="https://play.google.com/store/apps/details?id=com.agencyportal.com" rel="noopener noreferrer" target="_blank">
                     <img src={play_store} alt="Play Store" loading='lazy' />
                  </a>
               </div>
               <img src={i_phone} alt="i_phone" className="i_phone" loading='lazy' />
               <div className="app_content">
                  <h5>Exclusive App For Employees</h5>
                  <p>
                     Our employee app is robust, full of useful engaging features, giving your staff more control. Some of the features <br /> include, shift
                     management, where staff can pick up open shifts, request shift cancellations as well swap shifts. <br /> They can record their exact work
                     hours and attendance by clock in and out on the app. The app also includes <br /> Availability management, shift and compliance reminders.
                  </p>
                  <Link className="purple_bg mt-4" to="/application-feature">
                     View Features
                     <img src={wt_rt_arw} alt="wt_rt" loading='lazy' />
                  </Link>
               </div>
            </div>
            {/* Exclusive App Section - End  */}

            <div className="top-clicks" id="commonArrow" onClick={this.upperFun}>
               <i className="fa fa-long-arrow-up" aria-hidden="true"></i>
            </div>
            {this.state.showLoginModal === true ? (
               <AuthetiCationModal
                  loginModal={this.state.showLoginModal}
                  closeModal={e => this.closeSigninModal(e)}
                  autheticateTab={this.state.autheticateTab}
               />
            ) : null}
            <Footer></Footer>
         </>
      )
   }
}

function TestimonialTabs() {
   const [activeTab, setActiveTab] = useState('1')

   const toggle = tab => {
      if (activeTab !== tab) {
         setActiveTab(tab)
      }
   }

   return (
      <div className="testimonial-tabs">
         <Card>
            <Nav tabs className="">
               <NavItem>
                  <NavLink
                     className={activeTab === '1' ? 'active' : ''}
                     onClick={() => {
                        toggle('1')
                     }}
                  >
                     Lisa G
                  </NavLink>
               </NavItem>
               <NavItem>
                  <NavLink
                     className={activeTab === '2' ? 'active' : ''}
                     onClick={() => {
                        toggle('2')
                     }}
                  >
                     Derek H
                  </NavLink>
               </NavItem>
               <NavItem>
                  <NavLink
                     className={activeTab === '3' ? 'active' : ''}
                     onClick={() => {
                        toggle('3')
                     }}
                  >
                     Raj P
                  </NavLink>
               </NavItem>
               <NavItem>
                  <NavLink
                     className={activeTab === '4' ? 'active' : ''}
                     onClick={() => {
                        toggle('4')
                     }}
                  >
                     Naomi K
                  </NavLink>
               </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
               <TabPane tabId="1">
                  <CardBody>
                     <img src={double_qot_top} alt="double" loading='lazy' />
                     <CardText className="">
                        For our small business, Get Shifts' easy invoicing and compliance tools are a godsend, saving time and hassle.
                     </CardText>
                     <CardTitle className="">Lisa G</CardTitle>
                     <p className="owner">Agency Owner</p>
                     <img className="double_qot_bot" src={double_qot_bot} alt="doubleqoy" loading='lazy' />
                  </CardBody>
               </TabPane>
               <TabPane tabId="2">
                  <CardBody>
                     <img src={double_qot_top} alt="" loading='lazy' />
                     <CardText className="">
                        Get Shifts revolutionised our temp staffing with superb shift management. Incredibly efficient for our agency.
                     </CardText>
                     <CardTitle className="">Derek H</CardTitle>
                     <p className="owner">Agency Owner</p>
                     <img className="double_qot_bot" src={double_qot_bot} alt="double_qot_bot" loading='lazy' />
                  </CardBody>
               </TabPane>
               <TabPane tabId="3">
                  <CardBody>
                     <img src={double_qot_top} alt="double_qot_bot"  loading='lazy' />
                     <CardText className="">
                        In recruitment, communication and efficiency are key. Get Shifts excels at both, streamlining our entire process.
                     </CardText>
                     <CardTitle className="">Raj P</CardTitle>
                     <p className="owner">Agency Owner</p>
                     <img className="double_qot_bot" src={double_qot_bot} alt="double_qot_bot" loading='lazy' />
                  </CardBody>
               </TabPane>
               <TabPane tabId="4">
                  <CardBody>
                     <img src={double_qot_top} alt="double_qot_bot" loading='lazy' />
                     <CardText className="">
                        As a care agency, tracking recruitment and managing shifts is crucial. Get Shifts makes it seamless and effective..
                     </CardText>
                     <CardTitle className="">Naomi K</CardTitle>
                     <p className="owner">Agency Owner</p>
                     <img className="double_qot_bot" src={double_qot_bot} alt="double_qot_bot" loading='lazy'/>
                  </CardBody>
               </TabPane>
            </TabContent>
         </Card>
      </div>
   )
}

// export default TestimonialTabs;




// import React, { useState, useEffect, useRef } from 'react';

const VideoEmbed = () => {
   const [isIframeVisible, setIframeVisible] = useState(false);
   const iframeRef = useRef(null);

   const handleClick  = ()=>{
      setIframeVisible(true)
   }

   return (
      <div style={{ position: 'relative', overflow: 'hidden', aspectRatio: '1920 / 1080' }} ref={iframeRef} onClick={handleClick}>
         {isIframeVisible && (
            <iframe
               src="https://share.synthesia.io/embeds/videos/0bdc7b20-2654-44d9-b4ad-8ae233bd7c33?autoplay=1"
               title="Synthesia video player - Get Shifts: Temporary Staffing Solution"
               allow="encrypted-media; fullscreen"
               // style={{
               //    position: 'absolute',
               //    inset: 0,
               //    border: 'none',
               // }}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  border: 'none',
                  padding: 0,
                  margin: 0,
                  overflow: 'hidden'
               }}
            />   
         ) }
         <img  style={{borderRadius:"10px" ,cursor:"pointer"}} src={embeds_bg} alt='embeds_bg' loading='lazy'/>
      </div>
   );
};

// export default VideoEmbed;
